import { yupResolver } from "@hookform/resolvers/yup";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import PresentationPage from "./presentation-page";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Notification from "../../../Notification";
import { retrieveError } from "../../../utilities/constants";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import Swipper from "./swipper";

const SignIn = () => {
  const { t, i18n } = useTranslation();
  const history = useNavigate();
  const location = useLocation();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const [visibleRedirectGetToken, setVisibleRedirectGetToken] = useState(false);

  const [valueEmail, setValueEmail] = useState("");
  const [valuePassword, setValuePassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth <= 960 ? true : false
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 960);
    };

    window.addEventListener("resize", handleResize);

    // Clean up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClickShowPassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const mutation = useMutation({
    mutationFn: (user) => {
      return axios.post("https://api-dev.seeks.biz/users/token/", user);
    },
    onError: (error) => {
      setNotify({
        isOpen: true,
        message: error.response.data.detail
          ? t(error.response.data.detail)
          : retrieveError(error.response.data),
        type: "error",
      });
      if (
        error.response &&
        error.response.data &&
        error.response.data.email &&
        error.response.data.email === "Ce compte n'est pas activé"
      ) {
        setVisibleRedirectGetToken(true);
      }
      console.log("Erreur lors de la soumission du formulaire : ", error);
    },
    onSuccess: (data, variables, context) => {
      setNotify({
        isOpen: true,
        message: t("Connected successfully!"),
        type: "success",
      });
      localStorage.setItem("access_token", data.data.access);
      localStorage.setItem("refresh_token", data.data.refresh);
      localStorage.setItem("avatar", data.data.avatar);
      localStorage.setItem(
        "fullname",
        data.data.first_name + " " + data.data.last_name
      );
      localStorage.setItem("email", data.data.email);
      localStorage.setItem("words", JSON.stringify(data.data.words));
      localStorage.setItem("address", data.data.address);
      localStorage.setItem("longitude", data.data.longitude);
      localStorage.setItem("latitude", data.data.latitude);
      if (location && location.state && location.state.from) {
        window.location.href = location.state.from;
      } else {
        window.location.href = "/";
      }
    },
  });

  const handleChangeEmail = (e) => {
    setValueEmail(e.target.value);
  };

  const handleChangePassword = (e) => {
    setValuePassword(e.target.value);
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t("Invalid email"))
      .required(t("Email is required")),
    password: yup.string().required(t("Password is required")),
  });

  const { handleSubmit, control, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = () => {
    mutation.mutate({
      password: getValues("password"),
      email: getValues("email"),
    });
  };

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    window.scrollTo({
      top: windowHeight,
      behavior: "smooth",
    });
  };

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <section className="sign-in-page">
          <div id="container-inside">
            <div id="circle-small"></div>
            <div id="circle-medium"></div>

            <div id="circle-large"></div>
            <div id="circle-xlarge"></div>
            <div id="circle-xxlarge"></div>
          </div>
          <Container className="p-0">
            <Row className="no-gutters">
              {!isMobileView ? (
                <Col
                  md="6"
                  className="text-center pt-5 order-md-1 order-2"
                  style={{ maxWidth: "50%" }}
                >
                  <Swipper />

                  <div className="container-scroll">
                    <div className="center-text-arrow" onClick={handleScroll}>
                      <Trans>Learn more</Trans>
                    </div>

                    <svg class="arrows">
                      <path class="a1" d="M0 0 L30 32 L60 0"></path>
                      <path class="a2" d="M0 20 L30 52 L60 20"></path>
                      <path class="a3" d="M0 40 L30 72 L60 40"></path>
                    </svg>
                  </div>
                </Col>
              ) : (
                <div className="container-scroll">
                  <div className="scroll-down"></div>
                </div>
              )}
              <Col
                md={!isMobileView ? "6" : "12"}
                className="bg-black pt-5 pt-5 pb-lg-0 pb-5 order-md-2 order-1"
                style={{
                  overflowY: "auto",
                  maxHeight: "100vh",
                  marginTop: isMobileView ? "35%" : null,
                }}
              >
                <form onSubmit={handleSubmit(handleLogin)}>
                  <div className="sign-in-from">
                    <h1 style={{ color: "#c7c7c7" }}>
                      <Trans>Sign in</Trans>
                    </h1>
                    <p style={{ color: "#c7c7c7" }}>
                      <Trans>
                        Enter your email address and password to access the app.
                      </Trans>
                    </p>
                    {visibleRedirectGetToken ? (
                      <span className="dark-color d-inline-block line-height-2 text-danger">
                        <Trans>
                          Looks like your account is inactive. Verify your email
                          for your activation link (valid for 24 hours only) or
                        </Trans>
                        <Link to="/auth/activation/getToken">
                          <Trans> click here to request a new token</Trans>
                        </Link>
                        <br />
                      </span>
                    ) : null}
                    <Controller
                      name="email"
                      control={control}
                      key="email"
                      render={({ field }) => (
                        <TextField
                          label={t("Email")}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          {...field}
                          // onChange={handleChangeEmail}
                        />
                      )}
                    />

                    <Controller
                      name="password"
                      control={control}
                      key="password"
                      render={({ field }) => (
                        <TextField
                          label={t("Password")}
                          variant="outlined"
                          type={showPassword ? "text" : "password"}
                          fullWidth
                          margin="normal"
                          {...field}
                          // onChange={handleChangePassword}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{ marginRight: "8px" }}
                              >
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  size="large"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                    <div className="d-inline-block w-100">
                      {/* <Form.Check className="d-inline-block mt-2 pt-1">
                    <Form.Check.Input
                      type="checkbox"
                      className="me-2"
                      id="customCheck11"
                    />
                    <Form.Check.Label>
                      <Trans>Remember Me</Trans>
                    </Form.Check.Label>{" "}
                  </Form.Check> */}

                      <span className="dark-color d-inline-block line-height-2">
                        <Link to="/auth/recoverpw">
                          <Trans>Forgot password?</Trans>
                        </Link>
                      </span>
                      <Button
                        type="submit"
                        variant="primary"
                        className="float-end"
                      >
                        <Trans>Sign in</Trans>
                      </Button>
                    </div>
                    <div className="sign-info">
                      <span
                        className="d-inline-block line-height-2"
                        style={{ color: "#c7c7c7" }}
                      >
                        <Trans>Don't have an account? </Trans>
                        <Link to="/auth/sign-up">
                          <Trans>Sign up</Trans>
                        </Link>
                      </span>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </Container>

          <Notification notify={notify} setNotify={setNotify} />
        </section>
        <section className="presentation-section">
          <PresentationPage />
        </section>
      </ThemeProvider>
    </>
  );
};

export default SignIn;
